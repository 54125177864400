const bingMapsKey = require('../../config/bingMapKey');
module.exports = {
    apiUrlUb1: 'legacy.design.unirac.com/',
    developerEmail: 'unirac@unirac.com',
    apiUrl: 'design.unirac.com/',
    https: true,
    actionLogger: false,
    disableRaven: false,
    googleMapsKey: 'AIzaSyAKpYZTP0stvbFgs29p2PqWYVo8Laliiek',
    googleZipCodeKey: 'AIzaSyCHdPhyTFh15YrPCE9m5xfR9F4EpbmviJ8',
    bingMapsKey,
    googleAnalyticsTrackingId: 'UA-200757304-1',
    sentryLink: 'https://39aaa144fca541dbb1ebaf604f34b8ac@o462086.ingest.sentry.io/5597098',
    sentryProjectName: 'react-production',
    release: process.env.COMMIT_HASH,
    isProd: true,
    cacheMemoizeTimeout: 30000,
    lruSize: 5,
    ssoLogoutUrl: 'https://services.unirac.com/login?action=logout',
    ssoLoginUrl: 'https://services.unirac.com/login?next=https://design.unirac.com/',
    ssoRegistrationUrl: 'https://services.unirac.com/registration',
    ssoLoginUrlWithNext: 'https://services.unirac.com/login?next=',
    applicationUrl: 'https://design.unirac.com',
    loginPageRedirection: 'https://services.unirac.com/login?next=https://design.unirac.com/',
    domainName: 'unirac.com',
    currentProjectVersion: '0.0.63',
  };
  
