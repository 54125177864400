import { dispatch, state } from '__common/store';
import { getAreaByZip } from '__editor/components/roofsSelector/components/roofsSelectorMap/utils/getAreaByZip';
import { getProductName, isSolarMount, isRMDT, isRMFamily, isRMGridFlex, isEcoFoot2Plus,isRMIFIProduct, isSFMFamily, isGFT,
  isSfmInfinity, isMetalX, isSF, isNxtHorizon, isRM10Evolution, isRM5, isULA, isRM10, isSMTiltPR, isAscender, isRM10orRM10Evo, isCanadianChangesProduct, isRmGridflex10, isSMTilt, isNxtTilt } from '__common/constants/products';
import { getProjectConfigurationData } from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/utils/getProjectConfigurationData';
import { inchesToMeters } from '__common/calculations/inchesToMeters';
import { PREVENT_AUTO_SAVE_FINISHED, PREVENT_AUTO_SAVE_START } from './saveLoadProjectActions';
import { SET_CITY_NAME, SET_PRODUCT_ID, SET_PROJECT_CONFIGURED, SET_DEAD_LOAD_FACTOR_MODIFICATION, SET_PROJECT_VERSION, SET_ADDITIONAL_LOAD_COMBINATION, SET_INPUT_UNIT, SET_GONE_THROUGH_THE_PANEL_EDITOR, SET_ADJUSTABLE_TILT_SYSTEM, SET_PRODUCT_TITLE, SET_IS_AURORA_PROJECT, SET_FIELD_INVALID, SET_FLYOUT_VERIFIED, } from 'projectDesign/components/projectConfiguration/projectConfigurationActions';
import {
  CLEAR_DATA_TO_LOAD,
  FETCH_MFGS_AND_MODELS_REQUEST,
  FETCH_PROJECT,
  LOAD_PROJECT_CONFIGURATION,
  LOAD_ROOFS_FROM_API,
  SELECT_ROOF_LEAD_EDGE,
  SET_BING_MAPS_ROOFS_SELECTOR,
  SET_GOOGLE_MAPS_ROOFS_SELECTOR,
  SET_MFG,
  SET_MODEL,
  SET_BLANK_MAPS_ROOFS_SELECTOR,
  SET_RAILS_PRODUCT_ID,
  SET_LOCATION,
  SET_MAP_STATE,
  SET_PROJECT_NAME,
  SET_USER_CLICKED_YES_OR_NO,
} from 'actions';
import { loadProjectModule } from '__common/components/moduleSelector/moduleSelector';
import { ADD_OBSTRUCTION, ADD_OBSTRUCTIONS, CALCULATE_ZONE_CLASSIFICATION, CHANGE_OBSTRUCTION_HEIGHT, REQUEST_ADD_PANELS, SET_COLUMN_SPACING, SET_PANELS_WIDTH_AND_HEIGHT, SET_ROW_SPACING } from '__editor/panelsEditor/actions';
import { ICITY_STATE } from '../roofsSelectorMap/utils/cityModel';
import _ from 'lodash';
import { fromBitwiseData } from './utils/bitDataConverter';
import nextUid from '__common/calculations/nextUid';
import { isBlankMap, MAP_TYPE } from '__common/constants/map';
import { transformFixMissingSiblingData } from './utils/transformFixMissingSiblingData';
import { parseSaveData } from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/utils/panelsSaveData';
import { getLatestSchemaVersion } from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/utils/getRoofModelVersion';
import { degreesToRadians } from '__common/calculations/degreesToRadians';
import { getRectangleCoordsFrom3Points, isObstructionEdgesLargeEnough } from '__editor/panelsEditor/components/obstructions/obstructions';
import { metersToInches } from '__common/calculations/metersToInches';
import { calcDistanceOfTwoPoints } from '__common/calculations/distanceBetweenTwoPoints';
import { RoofType, TileReplacementOrSolarhooks, RoofMaterialThickness, MidEndClampChoice, SmFlushRailType, RoofSubstrate, NxtHorizonRailType, DEFAULT_VERIFIED_FLYOUTS_DATA, DEFAULT_VERIFIED_FLYOUTS_DATA_FOR_AURORA_IMPORTED_PROJECTS, UanchorSubCategoryType } from 'projectDesign/components/projectConfiguration/utils/constants';
import { AttachmentType } from 'projectDesign/components/projectConfiguration/fields/attachmentType';
import { isCanadianZipcode, isOsbOrPlywood, showTornadoSpeedWarning } from 'projectDesign/components/projectConfiguration/utils/validation';
import { getProductSettings } from '__editor/panelsEditor/components/productsSettings/productsSettings';
import { adjustableTiltSystem, applyEcoFoot2PlusRM10andEvoSetbackChanges, applyPanelRotationFactorChanges, removeSMHDrailoptionULA, uAnchorAttachments } from '__common/utils/versionCompare/versionCompare';
import { cmsToInches } from '__common/calculations/unitConversions';
import { recalculateRmZones } from '__editor/panelsEditor/components/panels/utils/recalculateZones';
import { asce_705, asce_710, isASCE716or722 } from '__common/constants/buildingCodes';
import { applyRM5SetbackChanges, checkforBeforeRM5Revamp, } from '__common/utils/versionCompare/versionCompare';
import { removeSMButylAttachmentField, validateSetback } from 'projectDesign/components/projectConfiguration/utils/updateProjectOption';
import { getPanelSetBackDistance } from '__editor/panelsEditor/components/roofZones/utils/setBackDistanceCollisionDetections';
import { InputUnit, isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';
import { windExposureOption } from 'projectDesign/components/projectConfiguration/fields/types/windExposure';
import { convertToPixelPoints } from '__editor/panelsEditor/components/leadEdge/leadEdge';
import { getAngle } from '__editor/panelsEditor/components/leadEdge/utils/backgroundRoofEdgesRotation';
import { getCenterBgCords } from '__editor/panelsEditor/components/background/background';
import { footerHeight, headerHeight } from '__common/constants/layout_css_dims';
import tilt from 'projectDesign/components/projectConfiguration/fields/tilt';
import { getProductTitle } from '__common/constants/products_titles';
import { zoomOnBingAllRoofs } from '__editor/bingMapsRoofsSelector/components/bingMapsRoofsSelectorDrawingManager/bingMapsRoofsSelectorDrawingManager';
import { createPanel } from '__editor/panelsEditor/components/panels/utils/panelsManagment';
import { switchProjectToASCE716 } from 'projectDesign/components/asce716switch/asce716switch';
import { SEISMIC_IMPORTANCE_FACTOR_BASED_ON_RISK_CATGORY } from 'projectDesign/components/projectConfiguration/fields/seismicIp';
import { SNOW_IMPORTANCE_FACTOR_BASED_ON_RISK_CATGORY } from 'projectDesign/components/projectConfiguration/fields/snowIs';
import { SOIL_CLASS } from 'projectDesign/components/projectConfiguration/fields/soilClass';
import { ADDITIONAL_USER_INPUTS_PAGE } from 'projectDesign/components/projectConfiguration/utils/projectConfigurationDrawerPages';


const ROOF_PITCH_FROM_DEGRESS = {
  5: '1/12',
  9: '2/12',
  14: '3/12',
  18: '4/12',
  23: '5/12',
  27: '6/12',
  30: '7/12',
  34: '8/12',
  37: '9/12',
  40: '10/12',
  43: '11/12',
  45: '12/12',
};

export const loadProjectData = (productId: string, projectId: string) => {
  if (projectId) {
    dispatch(FETCH_PROJECT(projectId));
  }
};

export const loadProject = (projectData: any) => {
  const { app: { isProjectError } } = state()
  if (isProjectError) {
    projectData.product = projectData.project_configuration.productId
    projectData.project_configuration.product = projectData.project_configuration.productId
    projectData.project_configuration.model = projectData.project_configuration.model_id
    projectData.project_configuration.module = projectData.project_configuration.module_id
    projectData.project_configuration.version = projectData.version
  }

  const buildingCode = _.get(projectData, 'project_configuration.building_code', 1);
  dispatch(PREVENT_AUTO_SAVE_START());
  dispatch(SET_MAP_STATE(true));
  setProductId(projectData.project_configuration);
  setProjectVersion(projectData.project_configuration);
  setInputUnit(projectData.project_configuration.input_unit);
  setProjectConfigurationData(projectData);
  setRoofs(projectData, buildingCode, projectData.project_configuration.product, projectData.project_configuration.map_type);
  setObstructions(projectData);
  setMapType(projectData.project_configuration.map_type);
  setPanelsModel(projectData.project_configuration);
  setLocation(projectData.project_configuration.location);
  setCityName(projectData.project_configuration.zipcode);
  setWarnings(projectData.project_configuration.product);   // set warnings after loading project configurations
  if (isSolarMount(projectData.project_configuration.product) && projectData.project_configuration.tilt) {
    dispatch(SET_PRODUCT_TITLE(getProductTitle(99)))
  }

  if (isProjectError) {
    loadProjectModule(getProductName(projectData.project_configuration.product), projectData);
  } else {
    loadProjectModule(getProductName(projectData.project_configuration.product));
  }

  if (isRMFamily(projectData.project_configuration.product) && !projectData.project_configuration.dead_load_factor_modification) {
    dispatch(SET_DEAD_LOAD_FACTOR_MODIFICATION(0.6));
  }
  if ((isULA(projectData.project_configuration.product) || isGFT(projectData.project_configuration.product)) && !projectData.project_configuration.additional_load_combination) {
    dispatch(SET_ADDITIONAL_LOAD_COMBINATION(1.0));
  }
  if (isSMTilt(projectData.project_configuration.product) || projectData.project_configuration.product == 9 && !projectData.project_configuration.adjustable_tilt_system) {
    dispatch(SET_ADJUSTABLE_TILT_SYSTEM(9));
  }
  if (isSolarMount(projectData.project_configuration.product) || isAscender(projectData.project_configuration.product) || isNxtHorizon(projectData.project_configuration.product) || isSMTiltPR(projectData.project_configuration.product)) {
    setSpansProductId(projectData.project_configuration.product);
  }


  dispatch(SET_PROJECT_CONFIGURED());
  dispatch(CLEAR_DATA_TO_LOAD());
  dispatch(PREVENT_AUTO_SAVE_FINISHED());
  if (projectData.project_configuration.is_aurora_project && (projectData.project_configuration.building_code === 3 || projectData.project_configuration.building_code === 4)) {
    switchProjectToASCE716(projectData.project_configuration.product)
  }
  removeSMButylAttachmentField(projectData.project_configuration.product, projectData.project_configuration.roof_type, projectData.project_configuration.attachment_type);
  
  if((isEcoFoot2Plus(projectData.project_configuration.product) || isRM10orRM10Evo(projectData.project_configuration.product) || isRmGridflex10(projectData.project_configuration.product)) && !isASCE716or722(projectData.project_configuration.building_code) && projectData.project_configuration.soil_class == SOIL_CLASS.D_DEFAULT) {
    dispatch(SET_FIELD_INVALID('soil_class', "Please choose the valid soil class"));
    dispatch(SET_FLYOUT_VERIFIED(ADDITIONAL_USER_INPUTS_PAGE, false, true));
  }
};

function setProjectConfigurationDataForOldProjects(data: any,projectData: any) {
  const { projectConfiguration : { projectVersion }, user} = state()
  if(isSolarMount(projectData.product) && projectData.tilt === null) {
    if (data.roof_type === RoofType.TILE) {
      if (data.attachment_type === null || [AttachmentType.PM_9000S, AttachmentType.PM_ADJUST, AttachmentType.MINI_1SS,
      AttachmentType.STAND_2SS, AttachmentType.WIDE_2SS, AttachmentType.L_FOOT_ONLY,
      AttachmentType.FLASHKIT_PRO, AttachmentType.FLASHLOC_COMP_KIT,
      AttachmentType.FLASHLOC_DUO, AttachmentType.FLASHKIT_PRO_SB].includes(data.attachment_type)) {
        data.attachment_type = AttachmentType.SOLARHOOK_FLAT_TILE;
      }
      if (data.tile_replacement_or_solarhooks === null) {
        data.tile_replacement_or_solarhooks = TileReplacementOrSolarhooks.TILE_REPLACEMENT;
      }
    }
    if (data.roof_type === RoofType.STANDING_SEAM) {
      if (data.attachment_type === null || [AttachmentType.PM_9000S, AttachmentType.PM_ADJUST, AttachmentType.SOLARHOOK_FLAT_TILE,
      AttachmentType.SOLARHOOK_9_IN_BASE, AttachmentType.SOLARHOOK_7_IN_BASE, AttachmentType.SOLARHOOK_UNIVERSAL, AttachmentType.L_FOOT_ONLY,
      AttachmentType.FLASHKIT_PRO, AttachmentType.FLASHLOC_COMP_KIT,
      AttachmentType.FLASHLOC_DUO, AttachmentType.FLASHKIT_PRO_SB, AttachmentType.PERFORMANCE_HOOK].includes(data.attachment_type)) {
        data.attachment_type = AttachmentType.MINI_1SS;
        data.material_thickness = RoofMaterialThickness.GA_22;
      }
    }
    if (data.roof_type === RoofType.R_PANEL) {
      if (data.attachment_type === null || [AttachmentType.MINI_1SS, AttachmentType.STAND_2SS, AttachmentType.WIDE_2SS,
      AttachmentType.SOLARHOOK_FLAT_TILE, AttachmentType.SOLARHOOK_9_IN_BASE, AttachmentType.SOLARHOOK_7_IN_BASE, AttachmentType.SOLARHOOK_UNIVERSAL, AttachmentType.L_FOOT_ONLY,
      AttachmentType.FLASHKIT_PRO, AttachmentType.FLASHLOC_COMP_KIT,
      AttachmentType.FLASHLOC_DUO, AttachmentType.FLASHKIT_PRO_SB, AttachmentType.PERFORMANCE_HOOK].includes(data.attachment_type)) {
        data.attachment_type = AttachmentType.PM_9000S;
        data.material_thickness = RoofMaterialThickness.GA_24;
      }
    }
    if (data.roof_type === RoofType.SHINGLE) {
      if (data.attachment_type === null || [AttachmentType.MINI_1SS, AttachmentType.STAND_2SS, AttachmentType.WIDE_2SS,
      AttachmentType.SOLARHOOK_FLAT_TILE, AttachmentType.SOLARHOOK_9_IN_BASE, AttachmentType.SOLARHOOK_7_IN_BASE, AttachmentType.SOLARHOOK_UNIVERSAL,
      AttachmentType.PM_9000S, AttachmentType.PM_ADJUST, AttachmentType.PERFORMANCE_HOOK].includes(data.attachment_type)) {
        data.attachment_type = AttachmentType.FLASHKIT_PRO;
        data.roof_substrate = RoofSubstrate.OSB;
      }
    }
    if (data.roof_type === RoofType.OTHERS) {
      if (data.attachment_type === null) {
        data.attachment_type = AttachmentType.FLASHKIT_PRO;
      }
    }

    if (isOsbOrPlywood(data.roof_type, data.attachment_type, data.roof_substrate)) {
      if (data.rafter_spacing_inches === null || data.rafter_spacing_inches !== 1) {
        data.rafter_spacing_inches = 1;
      }
    }

    if (data.rail_type === null) {
      data.rail_type = SmFlushRailType.SM;
    }
    if (data.mid_clamps_choices === null || data.end_clamps_choices === null) {
      if (data.clamps_choices) {
        data.mid_clamps_choices = data.clamps_choices;
        data.end_clamps_choices = data.clamps_choices;
      } else {
        data.mid_clamps_choices = MidEndClampChoice.PRO_CLAMP;
        data.end_clamps_choices = MidEndClampChoice.PRO_CLAMP;
      }
    }
  }

  if (isSMTiltPR(projectData.product)) {
    if (data.rail_type === null) {
      data.rail_type = SmFlushRailType.SM;
    }
    if (data.mid_clamps_choices === null || data.end_clamps_choices === null) {
      if (data.clamps_choices) {
        data.mid_clamps_choices = data.clamps_choices;
        data.end_clamps_choices = data.clamps_choices;
      } else {
        data.mid_clamps_choices = MidEndClampChoice.PRO_CLAMP;
        data.end_clamps_choices = MidEndClampChoice.PRO_CLAMP;
      }
    }
  }

  if (isNxtHorizon(projectData.product)) {
    if (data.roof_type === RoofType.TILE) {
      if (data.attachment_type === null || [AttachmentType.PM_9000S, AttachmentType.PM_ADJUST, AttachmentType.MINI_1SS,
      AttachmentType.STAND_2SS, AttachmentType.WIDE_2SS].includes(data.attachment_type)) {
        data.attachment_type = AttachmentType.SOLARHOOK_FLAT_TILE;
      }
      if (data.tile_replacement_or_solarhooks === null) {
        data.tile_replacement_or_solarhooks = TileReplacementOrSolarhooks.TILE_REPLACEMENT;
      }
    }
    if (data.roof_type === RoofType.STANDING_SEAM) {
      if (data.attachment_type === null || [AttachmentType.PM_9000S, AttachmentType.PM_ADJUST, AttachmentType.SOLARHOOK_FLAT_TILE,
      AttachmentType.SOLARHOOK_9_IN_BASE, AttachmentType.SOLARHOOK_7_IN_BASE, AttachmentType.SOLARHOOK_UNIVERSAL, AttachmentType.PERFORMANCE_HOOK].includes(data.attachment_type)) {
        data.attachment_type = AttachmentType.MINI_1SS;
        data.material_thickness = RoofMaterialThickness.GA_22;
      }
    }
    if (data.roof_type === RoofType.R_PANEL) {
      if (data.attachment_type === null || [AttachmentType.MINI_1SS, AttachmentType.STAND_2SS, AttachmentType.WIDE_2SS,
      AttachmentType.SOLARHOOK_FLAT_TILE, AttachmentType.SOLARHOOK_9_IN_BASE, AttachmentType.SOLARHOOK_7_IN_BASE, AttachmentType.SOLARHOOK_UNIVERSAL, AttachmentType.PERFORMANCE_HOOK].includes(data.attachment_type)) {
        data.attachment_type = AttachmentType.PM_9000S;
        data.material_thickness = RoofMaterialThickness.GA_24;
      }
    }
    if (data.roof_type === RoofType.SHINGLE) {
      if (data.attachment_type === null || [AttachmentType.MINI_1SS, AttachmentType.STAND_2SS, AttachmentType.WIDE_2SS,
      AttachmentType.SOLARHOOK_FLAT_TILE, AttachmentType.SOLARHOOK_9_IN_BASE, AttachmentType.SOLARHOOK_7_IN_BASE, AttachmentType.SOLARHOOK_UNIVERSAL,
      AttachmentType.PM_9000S, AttachmentType.PM_ADJUST, AttachmentType.PERFORMANCE_HOOK].includes(data.attachment_type)) {
        data.attachment_type = AttachmentType.STRONGHOLD_ATT_KIT_COMP;
        data.roof_substrate = RoofSubstrate.OSB;
      }
    }
    if (isOsbOrPlywood(data.roof_type, data.attachment_type, data.roof_substrate)) {
      if (data.rafter_spacing_inches === null || data.rafter_spacing_inches !== 1) {
        data.rafter_spacing_inches = 1;
      }
    }
    if (data.rail_type === null) {
      data.rail_type = NxtHorizonRailType.NH;
    }
    if (data.mid_clamps_choices === null || data.end_clamps_choices === null) {
      data.mid_clamps_choices = MidEndClampChoice.NXT_HORIZON_COMBO_CLAMP;
      data.end_clamps_choices = MidEndClampChoice.NXT_HORIZON_COMBO_CLAMP;
    }
  }

  if (isSfmInfinity(projectData.product) || (isSolarMount(projectData.product) && projectData.tilt === null) || isNxtHorizon(projectData.product) || isSMTiltPR(projectData.product)) {
    if (data.topographical_factor_kzt === null || data.topographical_factor_kzt === 0) {
      data.topographical_factor_kzt = 1;
    }
  }

  if (isSFMFamily(projectData.product) || isSF(projectData.product) || (isSolarMount(projectData.product) && projectData.tilt === null) || isMetalX(projectData.product) || isNxtHorizon(projectData.product) || isSMTiltPR(projectData.product)) {
    if (data.shortest_building_length === null) {
      data.shortest_building_length = 20;
    }
  }

  if (isRMGridFlex(projectData.product)) {
    if (data.allow_manual_attachments === null) {
      data.allow_manual_attachments = 0;
    }

    if (data.central_rails_count === null) {
      data.central_rails_count = 0;
    }

    if(data.max_down_point_load_to_be_allowed === null) {
      data.max_down_point_load_to_be_allowed = 1;
    }

  }
  if(isRMDT(projectData.product)) {
    if(data.allow_mechanical_attachments === 1) {
      data.allow_mechanical_attachments = 0;
    }
  } 

  if (isRM10Evolution(projectData.product)) {
    if (data.parapet_height_input === null) {
      data.parapet_height_input = 0;
    }
  }

  if (isRMGridFlex(projectData.product) || isRmGridflex10(projectData.product) || isRM10(projectData.product) || isRM10Evolution(projectData.product) || isRM5(projectData.product)) {
    if (data.half_block_weight === null) {
      data.half_block_weight = 16;
    }
  }

  if (isULA(projectData.product)) {
    if (data.ns_diagnol_brace === null || data.ns_diagnol_brace === undefined) {
      data.ns_diagnol_brace = true;
    }
    if (removeSMHDrailoptionULA(projectVersion)) {
      if (data.clamps_location === null) {
        data.clamps_location = 1;
      }
    } else {
      if (data.is_sm_hd_rail && data.rail_type === null) {
        data.rail_type = 2;
      } else if(data.rail_type === null) {
        data.rail_type = 1;
      }
      if (data.is_sm_hd_rail && data.clamps_location === null) {
        if (data.clamp_type === '01') {
          data.clamps_location = 1;
        }
        if (data.clamp_type === '11') {
          data.clamps_location = 1;
        }
        if (data.clamp_type === '03') {
          data.clamp_type = '01';
          data.clamps_location = 3;
        }
        if (data.clamp_type === '13') {
          data.clamp_type = '11';
          data.clamps_location = 3;
        }
        if (data.clamp_type === '02') {
          data.clamps_location = 2;
        }
      }
      if (data.rail_type === 1 && data.clamp_type !== '11') {
        data.clamp_type = '01';
      }
    } 
  }

  if (isGFT(projectData.product)) {
    if (data.clamp_type === null && data.pro_clamps === 1) {
      data.clamp_type = '11'
    }
    if (data.clamp_type === null && data.pro_clamps === 0) {
      data.clamp_type = '01'
    }
  }

  if (isAscender(projectData.product)) {
    if (data.structure_type == null) {
      data.structure_type = 1;
    }
  }

  if (checkforBeforeRM5Revamp(projectData.version)) {
    if (data.allow_mechanical_attachments == 1) {
      data.allow_mechanical_attachments = 0;
    }
  }

  if ((isRM10Evolution(projectData.product) || isRM5(projectData.product) || isRMGridFlex(projectData.product) || isRmGridflex10(projectData.product)) && (!!projectData.uanchor_part_number)) {
    const u_anchor_part_number = projectData.uanchor_part_number
    data.uanchor_part_number = u_anchor_part_number
    const u_anchor_part_number_dismantle_array = u_anchor_part_number.match(/[A-Za-z]+|[0-9]+/g);
    if(!projectData.sub_category && (data.roof_type == 20||data.roof_type == 22)){
      if ([UanchorSubCategoryType.REGULAR, UanchorSubCategoryType.SOLVENT_BASED].includes(u_anchor_part_number_dismantle_array[3])) {
        data.sub_category = 1;
      }
      if ([UanchorSubCategoryType.TORCHED, UanchorSubCategoryType.SILICONE_BASED].includes(u_anchor_part_number_dismantle_array[3])) {
        data.sub_category = 2;
      }
      }
    if(!projectData.manufacturer){
      data.manufacturer = u_anchor_part_number_dismantle_array[2];
      }
    if(!projectData.colour){
      data.colour = u_anchor_part_number_dismantle_array[4];
      }
    if(!projectData.choice_of_fastend){
      data.choice_of_fastend = Number(u_anchor_part_number_dismantle_array[1]);
    }
  }

  if (isCanadianChangesProduct(projectData.product) && isCanadianZipcode(projectData.zipcode) && !isMetricUnit(projectData.input_unit)) {
    data.nbcc_roughness = projectData.wind_exposure == windExposureOption.inTown.value ? 0 : 1;
    data.nbcc_wind_pressure = 0.3 * (projectData.wind_speed / 85) || 0.3;
    data.nbcc_category = projectData.risk_category || 2;
  }

  if((data?.helioscope_id || projectData?.is_helioscope_project) && (isRM5(projectData.product) || isRM10Evolution(projectData.product) || isRMGridFlex(projectData.product) || isRmGridflex10(projectData.product)) && uAnchorAttachments(projectVersion)) {
    data.roof_type = 6;
  }  


  if (isRMGridFlex(projectData.product) || isRmGridflex10(projectData.product)) {
    if (user.isStaff) {
      if (projectData?.fully_adhered === null || projectData?.fully_adhered === undefined) {
        data.fully_adhered = 1;
      }
      if (!projectData?.wind_iw) {
        data.wind_iw = 1;
      }
      if (!projectData?.seismic_ip) {
        data.seismic_ip = SEISMIC_IMPORTANCE_FACTOR_BASED_ON_RISK_CATGORY[projectData?.risk_category - 1];
      }
      if (!projectData?.snow_is) {
        data.snow_is = SNOW_IMPORTANCE_FACTOR_BASED_ON_RISK_CATGORY[projectData?.risk_category - 1];
      }
    }
  }
}

export function setProjectConfigurationData(projectData: any) {
  const data = getProjectConfigurationData(projectData.project_configuration);
  dispatch(SET_PROJECT_NAME(projectData.project_configuration.custom_title));
  setProjectConfigurationDataForOldProjects(data, projectData.project_configuration);
  let { verified_flyouts, is_aurora_project } = projectData.project_configuration;
  dispatch(SET_IS_AURORA_PROJECT(is_aurora_project));
  verified_flyouts = verified_flyouts && Object.keys(verified_flyouts).length > 0 ? verified_flyouts : !is_aurora_project ? DEFAULT_VERIFIED_FLYOUTS_DATA : DEFAULT_VERIFIED_FLYOUTS_DATA_FOR_AURORA_IMPORTED_PROJECTS;
  // for old projects that have {page:boolean} type
  Object.entries(verified_flyouts).forEach(([page, value]) => verified_flyouts[page] = value.hasOwnProperty('checked') ? value : { checked: value, error: false });
  dispatch(LOAD_PROJECT_CONFIGURATION(data, projectData.project_configuration.product, verified_flyouts));
}

function getRoofs(projectData, buildingCode: number, productId: number, mapType: number) {
  const { columnSpacing: columnSpacingMeters } = getProductSettings(productId);
  const { projectConfiguration: { projectEnvConfig: { helioscope_id } } }=state();
  let columnSpacing = inchesToMeters(columnSpacingMeters);
  const panelPromises = [];
  if(helioscope_id){
    dispatch(SET_GONE_THROUGH_THE_PANEL_EDITOR(false));
  }
  const roofs = projectData.roofs.reduce(
    (acc, roof) => {
      let { module_width, module_length, input_unit, tilt, product, version } = projectData.project_configuration;
      if (isAscender(productId) || isMetricUnit(input_unit)) {
        module_width = cmsToInches(module_width)
        module_length = cmsToInches(module_length)
      }
      const roofId = roof.id;
      if(helioscope_id){
        roof.seen = false;
        roof.isConfirmedYesorNo = true;
      }
      roof.schemaVersion = getLatestSchemaVersion(roof);
      roof.coords = roof.roofCoords;
      roof.marker = roof.marker;
      // roof.bgScale = {
      //   x : 1.9968798751950079,
      //   y : 1.9968798751950079
      // }
      // roof.bgScale.x = 1.9968798751950079
      // roof.bgScale.y = 1.9968798751950079
      if (isBlankMap(getMapType(mapType)) && roof.roofCoordsPixels && roof.bgScale) {
        roof.roofEdgesPixiCords = roof.roofCoordsPixels.map(roofPoint => ({
          x: roofPoint.x / roof.bgScale.x,
          y: roofPoint.y / roof.bgScale.y,
        }));
      }
      // roof is stored as JSON on the backend.
      // So you whatever you save it will be returned.
      // bgOffset was added later, so chances are it may be empty for most of the projects.
      const { x : bgOffX, y: bgOffY } = getCenterBgCords(
          document.body.offsetWidth,
          document.body.offsetHeight - (headerHeight + footerHeight)
        )
      roof.bgOffset = roof.bgOffset === undefined ? <pixelPoint>{ x: bgOffX, y: bgOffY } : roof.bgOffset;
      roof.rowSpacing = inchesToMeters(roof.row_spacing_in || 0);
      if (isGFT(productId) || isAscender(productId)) {
        roof.tableRowSpacing = inchesToMeters(roof.table_row_spacing_in || 0);
      }
      const panels = createPanels(
        roof.panels,
        roof.pixelPerMeter,
        parseFloat(module_width),
        parseFloat(module_length),
        buildingCode,
        productId,
        roof.roofPitch || 0,
        Number(roof.structureTilt) || 0
      );
      if (isRMDT(productId)) {
        roof.panels = transformFixMissingSiblingData(panels);
      } else {
        roof.panels = panels;
      }
      // if (applyPanelRotationFactorChanges(version) && !roof.skewAnglePerArrayMap) {
      //   roof.skewAnglePerArrayMap = {};
      //   roof.panels.forEach(({ groupId }) => roof.skewAnglePerArrayMap[groupId] ? null : roof.skewAnglePerArrayMap[groupId] = roof.panelsRotationDegrees);
      // }

      roof.metersPerPixel = 1 / roof.pixelPerMeter;
      if (roof.roofPitch >= 0) {
        roof.roofPitch = (isRMGridFlex(productId) || isEcoFoot2Plus(productId) || isRM5(productId) || isRM10orRM10Evo(productId)  || isRmGridflex10(productId) || (isSmTilt(productId, tilt) && adjustableTiltSystem(projectData.project_configuration.version)) || isNxtTilt(productId)) ? String(roof.roofPitch) : ROOF_PITCH_FROM_DEGRESS[roof.roofPitch];
      }

      setLeadEdge(roofId, roof.leadEdge);
      if(!roof.bgRotation) {
        const paths = getLeadEdgeFromRoof(roof, roof.leadEdge);
        const centerPoint = roof.marker;
        const leadEdgeCords = convertToPixelPoints(paths, centerPoint, roof.zoom);
        const rotationDegrees = getAngle(leadEdgeCords, roof.coords, centerPoint, roof.zoom);
        roof.bgRotation = rotationDegrees;
      }
      if(projectData.project_configuration.is_helioscope_project || applyEcoFoot2PlusRM10andEvoSetbackChanges(product, version)) {
        if(panels.length > 0 && roof?.panels?.some(panel => panel.panelSetback===0)) {
          let setbackCalculatedPanels = []
          if(isRM5(productId) && mapType===3){
            setbackCalculatedPanels = roof.panels.map((panel)=> {
              panel.panelSetback=3
              return panel
            })
          } else {
            setbackCalculatedPanels = calculateSetbackDistance(roof);
          }
          roof.panels = setbackCalculatedPanels
        }
      }

      if ((isRMDT(productId) || isRM5(productId)) && panels.length > 0 && roof.panels[0].roofZone === 0 && (buildingCode === asce_710.value || buildingCode === asce_705.value)) {
        dispatch(CALCULATE_ZONE_CLASSIFICATION(true))
        let updatedPanels = recalculateRmZones(productId, roof.rowSpacing, columnSpacing, roof.metersPerPixel, roof.panels, roof.id)
          .then(updated_panels => roof.panels = updated_panels.length > 0 ? updated_panels : roof.panels)
        panelPromises.push(updatedPanels)
      }

      delete roof.pixelPerMeter;
      delete roof.roofCoords;
      delete roof.roofCoordsPixels;
      acc[roofId] = roof;
      return acc;
    },
    {},
  );
  if ((isRMDT(productId) || isRM5(productId)) && panelPromises.length > 0) {
    Promise.all(panelPromises).then(
      () => {
        dispatch(CALCULATE_ZONE_CLASSIFICATION(false))
      }
    )
  }
  return roofs;
}

function calculateSetbackDistance(roof) {
  const panels = roof.panels;
  const {
    bgRotation,
    roofPitch,
    zoom,
    metersPerPixel,
    marker: roofCenter,
    coords: roofEdges,
    bgOffset
  } = roof;
  const updatedPanels = panels.map(panel => {
    panel.panelSetback = getPanelSetBackDistance(
      panel,
      true,
      roofEdges,
      roofCenter,
      zoom,
      metersPerPixel,
      bgRotation,
      bgOffset.x,
      bgOffset.y,
      panel.width,
      panel.height,
      roofPitch,
    );
    return panel;
  });
  return updatedPanels;
}


function setSpacings(projectData, roofs) {
  const { project_configuration : { tilt, product: productId, version, mid_clamps_choices } } = projectData;
  const { rowSpacing: rowSpacingInches, columnSpacing: columnSpacingInches } = getProductSettings(productId, version);
  if ((isSolarMount(productId) && tilt === null) || isNxtHorizon(productId) || isSMTiltPR(productId)) {
    if (isSMTiltPR(productId)) {
      let rowSpacing = inchesToMeters(rowSpacingInches);
      if (Object.keys(roofs).length > 0) {
        rowSpacing = roofs[Object.keys(roofs)[0]].rowSpacing;
      }
      if (rowSpacing) dispatch(SET_ROW_SPACING(rowSpacing));
      if (mid_clamps_choices === 1) {
        dispatch(SET_COLUMN_SPACING(inchesToMeters(1)));
      } else if (mid_clamps_choices === 2) {
        dispatch(SET_COLUMN_SPACING(inchesToMeters(0.25)));
      } else if (mid_clamps_choices === 3) {
        dispatch(SET_COLUMN_SPACING(inchesToMeters(0.5)));
      }
    }
    else {
      if (mid_clamps_choices === 1) {
        dispatch(SET_ROW_SPACING(inchesToMeters(1)));
        dispatch(SET_COLUMN_SPACING(inchesToMeters(1)));
      } else if (mid_clamps_choices === 2) {
        dispatch(SET_ROW_SPACING(inchesToMeters(0.25)));
        dispatch(SET_COLUMN_SPACING(inchesToMeters(0.25)));
      } else if (mid_clamps_choices === 3) {
        dispatch(SET_ROW_SPACING(inchesToMeters(0.5)));
        dispatch(SET_COLUMN_SPACING(inchesToMeters(0.5)));
      } else {
        dispatch(SET_ROW_SPACING(inchesToMeters(0.5)));
        dispatch(SET_COLUMN_SPACING(inchesToMeters(0.5)));
      }
    }
  } else {
    let rowSpacing = inchesToMeters(rowSpacingInches);
    if (Object.keys(roofs).length > 0 && (isRmGridflex10(productId) || isRMGridFlex(productId) || isRM5(productId) || isULA(productId))) {
      rowSpacing = roofs[Object.keys(roofs)[0]].rowSpacing;
    }
    if (rowSpacing) dispatch(SET_ROW_SPACING(rowSpacing));

    let columnSpacing = inchesToMeters(columnSpacingInches);
    if (isULA(productId)) {
      columnSpacing = rowSpacing;
    }
    if (columnSpacing) dispatch(SET_COLUMN_SPACING(columnSpacing));
  }
}

function setRoofs(projectData, buildingCode: number, productId: number, mapType: number) {
  const roofs = getRoofs(projectData, buildingCode, productId, mapType);
  const firstRoofPanels = Object.values(roofs).find(roof => roof?.panels)?.panels;
  if(firstRoofPanels){
    const panelWidth = firstRoofPanels[0]?.width
    const panelHeight = firstRoofPanels[0]?.height;
    dispatch(SET_PANELS_WIDTH_AND_HEIGHT(panelWidth, panelHeight))
  }
  dispatch(LOAD_ROOFS_FROM_API(roofs));
  setSpacings(projectData, roofs);
}


function setObstructions(projectData) {
  const productId = projectData.project_configuration.product;
  projectData.roofs.map(roof => {
    if (roof.obstructions) {
      if (isRMIFIProduct(productId)) {
        Object.keys(roof.obstructions).map(obstructionId => {

          const obstruction = roof.obstructions[obstructionId];

          const metersPerPixel = roof.metersPerPixel;

          let points = obstruction.coords;
          if (points.length > 3) {
            points = points.splice(0, 3);
          }
          let [obstructionLength, obstructionWidth] = [undefined, undefined];
          if (points.length === 3) {
            points = getRectangleCoordsFrom3Points(points);
            obstructionLength = metersToInches(calcDistanceOfTwoPoints(points[0], points[1]) * metersPerPixel);
            obstructionWidth = metersToInches(calcDistanceOfTwoPoints(points[1], points[2]) * metersPerPixel);
            const isLargeEnough = isObstructionEdgesLargeEnough(points, metersPerPixel);
            dispatch(ADD_OBSTRUCTION(roof.id, Number(obstructionId), obstruction.name, obstructionLength, obstructionWidth, points, isLargeEnough, metersPerPixel));
            dispatch(CHANGE_OBSTRUCTION_HEIGHT(roof.id, Number(obstructionId), obstruction.height));
          }
        });
      } else {
        dispatch(ADD_OBSTRUCTIONS(roof.id, roof.obstructions));
      }
    }
  });
}


function setLeadEdge(roofId: number, leadEdgeNumber: number) {
  dispatch(SELECT_ROOF_LEAD_EDGE(leadEdgeNumber, roofId));
}

function createPanels(panels: panelsSaveData[], pixelPerMeter: number, module_width: number, module_height: number, buildingCode: number, productId: number, roofPitch: number, structureTilt: number) {
  let moduleWidth = module_width;
  if (isRMGridFlex(productId)) {
    moduleWidth = moduleWidth * Math.cos(degreesToRadians(5));
  }
  else if (isRmGridflex10(productId)) {
    moduleWidth = moduleWidth * Math.cos(degreesToRadians(10));
  }
  if (isSMTiltPR(productId)) {
    moduleWidth = moduleWidth * Math.cos(degreesToRadians(structureTilt));
  }
  const widthInMeters = inchesToMeters(moduleWidth);
  const heightInMeters = inchesToMeters(module_height);

  const width = widthInMeters * pixelPerMeter;
  const height = heightInMeters * pixelPerMeter;

  return panels.map((panel) => {
    let attached_gf10=undefined;
    if (isRmGridflex10(productId)){
      attached_gf10 = panel[6];
    }
    const { x, y, bitData, groupId, siblingId, siblingSide, panelEWPosition, pairId, roofZoneId, panelConfig, panelNSPosition, schemaVersion: parsedVersion, tableHeight, attached, panelSetback, manualAttachments, skewAffected } = parseSaveData(panel, productId);
    // if panels data doesn't carry any information about version we should assume the first version (old way of decoding bits)
    const schemaVersion = parsedVersion ? parsedVersion : 1;
    
    const { nearObstruction, roofZone, isLandscape, exposed, edgeType, exposedNeighbour, obstructionType } = fromBitwiseData(bitData, buildingCode, productId, schemaVersion, roofPitch, roofZoneId);
    
    const panelToload: panelInState = {
      x,
      y,
      width: isLandscape ? height : width,
      height: isLandscape ? width : height,
      id: nextUid(),
      roofZone,
      groupId,
      landscape: isLandscape,
    };

    // TODO: Refactor this using ternary operator

    // note that edgeType is an enum and one of the values is 0 which can make the if statement skip it.
    if (edgeType !== undefined) {
      panelToload.edgeType = edgeType;
    }

    if (exposedNeighbour !== undefined) {
      panelToload.exposedNeighbour = exposedNeighbour;
    }

    if (nearObstruction !== undefined) {
      panelToload.nearObstruction = nearObstruction;
    }

    if (exposed !== undefined) {
      panelToload.exposed = exposed;
    }

    if (siblingId !== undefined) {
      panelToload.siblingId = siblingId;
    }

    if (siblingSide !== undefined) {
      panelToload.siblingSide = siblingSide;
    }

    if (attached !== undefined || attached_gf10!==undefined) {
      panelToload.attached = Boolean(attached) || Boolean(attached_gf10);
    }

    if ([0, 1, 2].includes(panelEWPosition)) {
      panelToload.panelEWPosition = panelEWPosition;
    }

    if (panelNSPosition !== undefined) {
      panelToload.panelNSPosition = panelNSPosition;
    }

    if (tableHeight !== undefined) {
      panelToload.tableHeight = tableHeight;
    }

    if (obstructionType !== undefined) {
      panelToload.obstructionType = obstructionType;
    }
    if (pairId !== undefined) {
      panelToload.pairId = pairId;
    }

    if (roofZoneId !== undefined) {
      panelToload.roofZoneId = roofZoneId;
    }

    if (panelConfig !== undefined) {
      panelToload.panelConfig = panelConfig;
    }

    if (panelSetback !== undefined) {
      panelToload.panelSetback = panelSetback;
    }

    if(manualAttachments !== undefined){
      panelToload.manualAttachments = manualAttachments;
    }

    if(skewAffected) {
      panelToload.skewAffected = skewAffected;
    }

    return panelToload;
  });
}

export function setMapType(mapType: number) {
  const type = getMapType(mapType);
  switch (type) {
    case MAP_TYPE.GOOGLE:
      dispatch(SET_GOOGLE_MAPS_ROOFS_SELECTOR());
      break;
    case MAP_TYPE.BING:
      dispatch(SET_BING_MAPS_ROOFS_SELECTOR());
      break;
    case MAP_TYPE.BLANK:
      dispatch(SET_BLANK_MAPS_ROOFS_SELECTOR());
      break;
    default:
      dispatch(SET_GOOGLE_MAPS_ROOFS_SELECTOR());
      break;
  }
}

export function setCityName(zipcode: string) {
  getAreaByZip(zipcode, (data: ICITY_STATE) => dispatch(SET_CITY_NAME(`${data.city}, ${data.state}`)));
}

export function getMapType(mapType: number) {
  switch (mapType) {
    case 1:
      return MAP_TYPE.GOOGLE;
    case 2:
      return MAP_TYPE.BING;
    case 3:
      return MAP_TYPE.BLANK;
    default:
      return MAP_TYPE.GOOGLE;
  }
}

export function getPanelsModel(projectEnvConfig: any) {
  const {
    model,
    module_watts,
    module_width,
    module_length,
    module_thickness,
    module_weight,
  } = projectEnvConfig;

  return {
    id: model,
    module: projectEnvConfig.module,
    watts: module_watts,
    width: module_width,
    height: module_length,
    thickness: module_thickness,
    weight: module_weight,
  };
}


export function setPanelsModel(projectConfiguration) {
  const { model, module, module_length, module_thickness, module_watts, module_weight, module_width, version, product, input_unit } = projectConfiguration;
  const productName = getProductName(product);
  const customModel = {
    model,
    module,
    height: module_length,
    thickness: module_thickness,
    watts: module_watts,
    weight: module_weight,
    width: module_width,
  };
  dispatch(SET_MFG(projectConfiguration.module));
  dispatch(SET_MODEL(model, undefined, product, input_unit));
  dispatch(FETCH_MFGS_AND_MODELS_REQUEST(productName, projectConfiguration.module, model, customModel, version, input_unit));
}


export function setSpansProductId(spansProductId: number) {
  dispatch(SET_RAILS_PRODUCT_ID(spansProductId));
}

export function setLocation(location: string) {
  dispatch(SET_LOCATION(location));
}


function setProjectVersion(projectConfig) {
  dispatch(SET_PROJECT_VERSION(projectConfig.version));
}

export const setInputUnit = (inputUnit: number | undefined | null) => {
  const input_unit = Boolean(inputUnit) ? inputUnit : InputUnit.CUSTOMARY
  dispatch(SET_INPUT_UNIT(input_unit))
};

function setWarnings(productId) {
  const { projectConfiguration : {projectEnvConfig, inputUnit, projectVersion}} = state();
  showTornadoSpeedWarning();
  validateSetback(productId, projectEnvConfig, projectVersion, inputUnit);
}


function setProductId(projectEnvConfig: projectEnvConfig) {
  if (isSmTilt(projectEnvConfig.product, projectEnvConfig.tilt)) {
    dispatch(SET_PRODUCT_ID(99));
  } else {
    dispatch(SET_PRODUCT_ID(projectEnvConfig.product));
  }
}

function isSmTilt(productId: number, tilt: number | null) {
  return isSolarMount(productId) && !isNaN(tilt) && tilt !== null;
}

function getLeadEdgeFromRoof(roof: drawingManagerRoof, leadEdgeNumber: number): cordPoint[] {
  const coords = roof.coords;
  const path1 = coords[leadEdgeNumber];
  let path2 = coords[leadEdgeNumber + 1];

  if (path2 === undefined) {
    path2 = coords[0];
  }

  return [path1, path2];
}

