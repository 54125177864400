import { asce_710} from "__common/constants/buildingCodes";
import { isRM10, isRM10Evolution, isRM5, isRMDT, isRMGridFlex, isRMIFIProduct, isRmGridflex10 } from "__common/constants/products";
import { AttachmentTypeShearIFIMaxLimit, AttachmentTypeShearRM10MaxLimit, AttachmentTypeShearRM10MaxLimitKg, AttachmentTypeShearRM5MaxLimit } from "../../fields/shearAllowable";
import { SOIL_CLASS } from "../../fields/soilClass";
import { RoofExposureOption } from "../../fields/types/roofExposure";
import { windExposureOption } from "../../fields/types/windExposure";
import { AttachmentTypeUpliftIFIMaxLimit, AttachmentTypeUpliftRM10MaxLimit, AttachmentTypeUpliftRM10MaxLimitKg, AttachmentTypeUpliftRM5MaxLimit } from "../../fields/upliftAllowable";
import { calculateKe, calculateKz, get_numerical_coefficient, get_design_life_factor } from "../coefficients";
import { isCanadianZipcode } from "../validation";
import store from "__common/store";

export default (product) => {
  
  const default_MRI = isCanadianZipcode(store.getState().projectConfiguration.projectEnvConfig.zipCode) || isRMGridFlex(product) || isRmGridflex10(product) ? 50 : 25;
  const DEFAULT_VALUES = {
    elevation : 5499,
    wind_speed : 115,
    wind_exposure : 'B',
    building_code : asce_710.value,
  }
  const allRms = {
    zipcode: '',
    client_name: '',
    building_code: DEFAULT_VALUES.building_code,
    wind_speed: 115,
    snow_load: 5,
    seismic_ss: 0.46,
    elevation: 5499,
    mean_recurrence_interval: default_MRI,
    block_weight: 32,
    wind_exposure: 'B',
    dead_load_factor_modification: 0.6,
    risk_category: 2,
    input_unit: 1,
    ...(isRMIFIProduct(product) || isRmGridflex10(product)? {
      allow_manual_attachments:0,
      // parapet_height_input: 5,
      seismic_s1: 0,
      deflectors: isRmGridflex10(product) ? 1 : 0,
      soil_class: 4,
      psf_control: isRmGridflex10(product) ? 2 : 1,
      topographical_factor_kzt: 1,
      tilt: isRmGridflex10(product)?10:5,
      central_rails_count: 0,
      allow_secondary_ballast_tray: 0,
    } : {}
    ),
    ...(isRM5(product) ? {
      anchor_type: 1,
      deflectors: 1,
      module_type: 2,
      allow_manual_attachments: 0
      } : {}
    ),
    ...((isRMIFIProduct(product) || isRmGridflex10(product) || isRM10(product) || isRM10Evolution(product) || isRM5(product)) ? {
      setback_distance: 3,
      allow_mechanical_attachments: 1,
      attachment_optimization_criteria: 2,
      attachment_type: 1,
      is_half_block_allowed: false,
      half_block_weight: 16,
      seismic_s1: 0,
      tornado_speed: 0,
      building_height_greater_than_6_storey: 0,
      ...(isRM10(product) || isRM10Evolution(product) || isRM5(product) ? {
        central_support: 0,
        psf_control: 2,
        nbcc_wind_pressure: 0.30,
        nbcc_category: 2,
        nbcc_roughness: 0,
        rain_load: 0.1,
        ...(isCanadianZipcode(store.getState().projectConfiguration.projectEnvConfig.zipCode)) ? {
          uplift_allowable: AttachmentTypeUpliftRM10MaxLimitKg.UNIRAC_FLASHLOC_RM,
          shear_allowable: AttachmentTypeShearRM10MaxLimitKg.UNIRAC_FLASHLOC_RM,
        } :
        {
          uplift_allowable: AttachmentTypeUpliftRM10MaxLimit.UNIRAC_FLASHLOC_RM,
          shear_allowable: AttachmentTypeShearRM10MaxLimit.UNIRAC_FLASHLOC_RM
        }
      } : {
        uplift_allowable:  AttachmentTypeUpliftIFIMaxLimit.UNIRAC_FLASHLOC_RM,
        shear_allowable: AttachmentTypeShearIFIMaxLimit.UNIRAC_FLASHLOC_RM,
      })
    } : {}),
    ...(isRM10Evolution(product) || isRmGridflex10(product) ? {
      allow_north_bays: 1,
      seismic_sds: 0.01,
      seismic_sd1: 0.01,
    } : {}),
    ...(isRM10(product) || isRM10Evolution(product) || isRmGridflex10(product) || isRMGridFlex(product) ? {
      allow_manual_attachments: 0,
      soil_class: SOIL_CLASS.D,
      velocity_pressure_exposure_coefficient_kz: calculateKz(DEFAULT_VALUES.wind_exposure, 15, ''),
      ground_elevation_factor_ke: calculateKe(DEFAULT_VALUES.elevation),
      wind_directionality_factor_kd: 0.85,
      roof_exposure: RoofExposureOption.partiallyExposed.value,
      numberical_coefficient: get_numerical_coefficient(DEFAULT_VALUES.building_code, DEFAULT_VALUES.elevation),
      topographical_factor_kzt: 1,
      design_life_factor_fc: get_design_life_factor(default_MRI),
    } : {}),
    ...((isRMIFIProduct(product) || isRmGridflex10(product) || isRM10(product) || isRM10Evolution(product) || isRMDT(product) || isRM5(product)) ? {
      limit_down_point_loads: 0,
      } : {}),
    ...(isRM5(product) ? {
     central_support: 0,
     soil_class: SOIL_CLASS.D_DEFAULT,
     uplift_allowable: AttachmentTypeUpliftRM5MaxLimit.UNIRAC_FLASHLOC_RM,
     shear_allowable: AttachmentTypeShearRM5MaxLimit.UNIRAC_FLASHLOC_RM,
     } : {}),
    ...(isRMGridFlex(product) || isRmGridflex10(product) ? {
      mandatory_mid_support: 0,
      thermal_factor: 1,
      load_sharing_type: 'default',
      friction_factor: 0.5,
      fully_adhered: 1,
      seismic_ip: 1,
      snow_is: 1,
      wind_iw: 1,
      } : {}),
    ...(isRmGridflex10(product) ? {
      additional_rail: 1,
      blocks_orientation: 0,
      roofPsfLimit: 3,
      is_ul3741_required: 0,
    }: {}),
    ...(!isRmGridflex10(product) ? {
      tornado_speed: 0,
      seismic_sds: 0.01,
      seismic_sd1: 0.01,
    } : {}),
    ...(isRM10(product) || isRM10Evolution(product) ? {
      use_friction : 0,
      add_roof_pads_everywhere : 0,
      }:{}),
    ...(isRM5(product) ? {
      use_friction : 0,
      }:{}),
    };
  return allRms;
};
