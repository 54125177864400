import AppHistory from '__common/modules/history';
import { ActionTypes, LOCATION_CHANGE } from '../actionsConstants';
import { clearProjectData } from '__editor/components/roofsSelector/roofsSelectorHelper';
import { createSearchLink } from './app';
import { getProductName } from '__common/constants/products';
import { Action } from '__common/store/action';

export function goTo(pathname: string, options: any = {}) {
  return {
    type: LOCATION_CHANGE,
    payload: {
      action: 'PUSH',
      location: {
        pathname,
        search: options.search,
        state: options.state,
        activeTabIndex: options.activeTabIndex,
      },
    },
  };
}

export function showAlert(status: string, message: string, withTimeout: boolean = true) {
  return {
    type: ActionTypes.SHOW_ALERT,
    status,
    message,
    withTimeout,
  };
}

export function hideAlert() {
  return {
    type: ActionTypes.HIDE_ALERT,
  };
}

export function changeLanguage(lang: String) {
  return {
    type: ActionTypes.CHANGE_LANGUAGE,
    payload: {
      lang,
    },
  };
}

export function WAIT_FOR_SOCKET() {
  return {
    type: ActionTypes.WAIT_FOR_SOCKET,
  };
}

export function MULTI_ACTIONS_DISPATCH_DONE() {
  return {
    type: ActionTypes.MULTI_ACTIONS_DISPATCH_DONE,
  };
}

export function AJAX_ERROR() {
  return {
    type: ActionTypes.AJAX_ERROR,
  };
}

export function SEARCH_PROJECT(projectId: string) {
  return {
    type: ActionTypes.SEARCH_PROJECT,
    payload: {
      projectId,
    },
  };
}

export function COPY_PROJECT(projectId: string) {
  return {
    type: ActionTypes.COPY_PROJECT,
    payload: {
      projectId,
    },
  };
}

export function REDIRECT_TO_PROJECT(projectData: { product_id: number, project_id: string, is_sm_tilt: boolean, zipcode?: String }) {
  const productId = projectData.product_id === 9  && projectData.is_sm_tilt ? 99 : projectData.product_id;  
  clearProjectData(getProductName(productId), false, projectData?.project_id, projectData?.zipcode);
  const searchLink = createSearchLink(projectData.project_id, productId);
  AppHistory.push(searchLink);
  return {
    type: ActionTypes.REDIRECT_TO_PROJECT,
  };
}

export function PROJECT_NOT_FOUND() {
  AppHistory.push('/projectNotFound');
  return {
    type: ActionTypes.PROJECT_NOT_FOUND,
  };
}

export function EMPTY_APP_ACTION() {
  return {
    type: ActionTypes.EMPTY_APP_ACTION,
  };
}

export function SET_LOADER_MSG(loaderMsg: string) {
  return {
    type: ActionTypes.SET_LOADER_MSG,
    payload: {
      loaderMsg,
    },
  };
}

export function CLEAR_LOADER_MSG() {
  return {
    type: ActionTypes.CLEAR_LOADER_MSG,
  };
}

export function GET_APP_STATUS() {
  return {
    type: ActionTypes.GET_APP_STATUS,
  };
}

export function GET_APP_REVISION_HISTORY() {
  return {
    type: ActionTypes.GET_APP_REVISION_HISTORY,
  };
}

export interface APP_REVISION_HISTORY_PAYLOAD {
  revision_history: {
    application_revision?: string;
    project_version?: string;
    products_affected: string[];
    change_log: string[];
    date: string;
  }
}

export function SET_APP_REVISION_HISTORY(revision_history: APP_REVISION_HISTORY_PAYLOAD['revision_history']): Action<APP_REVISION_HISTORY_PAYLOAD> {
  return {
    type: ActionTypes.SET_APP_REVISION_HISTORY,
    payload: {
      revision_history,
    },
  };
}

export function GET_APP_STATUS_SUCCESS(data) {
  return {
    type: ActionTypes.GET_APP_STATUS_SUCCESS,
    payload: {
      message: data.message,
    },
  };
}

export function GET_APP_STATUS_FAILURE() {
  return {
    type: ActionTypes.GET_APP_STATUS_FAILURE,
  };
}

export function GET_APP_STATUS_CLEAR() {
  return {
    type: ActionTypes.GET_APP_STATUS_CLEAR,
  };
}

export function SET_INVALID_PROJECT(isProjectError:boolean) {
  return {
    type: ActionTypes.SET_INVALID_PROJECT,
    payload: {
      isProjectError,
    },
  };
}

export function SET_AURORA_IMPORT(isAuroraImport:boolean) {
  return {
    type: ActionTypes.AURORA_IMPORT,
    payload: {
      isAuroraImport,
    },
  };
}

export function CLEAR_AURORA_IMPORT() {
  AppHistory.push('/project/design/nxt_umount')
  return {
    type: ActionTypes.CLEAR_AURORA_IMPORT,
  };
}


