import { BomActionTypes } from 'actionsConstants';
import { createReducer } from '__common/utils/helpers';
import { isNxtHorizon, isSFMFamily, isSMTiltOrFlush, isSMTiltPR, products } from '__common/constants/products';
import { restorePartQty } from './components/utils/loadBomSelectionsQtys';
import {
  addSelectedFlashingToPartsList,
  addSelectedStandoffsToPartsList,
  addSFMAttachmentsToPartsList,
  enableAllParts,
  getSFMAttachmentsPartsNumbers,
  selectAttachment,
} from 'bom/bomHelpers';
import { addClamps, addNxtClamps } from './utils/clamps';
import { BND_T_BOLT_NUT_3_8_3_4_SS, PART_RM10_EVO_MODULE_CLAMP_BULK, PART_RM10_EVO_MODULE_CLAMP_KIT, PART_RM10_EVO_MODULE_CLAMP_SIDE_BOLT_BULK, mlpePartNumber, NEW_NXT_MLPE_LUG_CLAMP_FOR_LUG_V1 } from '__common/constants/parts';

export const bomState: bomState = {
  total_watts: 0,
  total_modules: 0,
  productId: 0,
  projectId: '',
  partsList: [],
  accessoriesList: [],
  attachmentsList: [],
  tileReplacementList: {},
  selectedAttachmentType: { selected: false, parts: [] },
  extraParameters: {},
  isLoading: false,
  bomSelectionsLoading: false,
  stdClampsFetching: false,
  stdClampsFetched: false,
  isReady: false,
  project_name: '',
  error: null,
  bomPk: null,
  reload_selections: false,
  goToEngineeringFromBom: false,
  bomChanged: false,
  created: false,
  isClampsChanged: false,
  structureTypeCount: 0,
  auroraReport: {
    show: false,
    diableRetryButton: null,
    auroraReportsDataUrl: null,
    loading: null,
    error: null,
    errorMessages: null,
  },
};

export default {
  bom: createReducer(bomState, {
    [BomActionTypes.ADD_PART_TO_BOM](state, action) {
      const { part } = action.payload;
      const partsList = state.partsList;
      restorePartQty(state, part);
      partsList.push(part);
      return { ...state, partsList };
    },
    [BomActionTypes.ADD_RM10_MODULE_AND_HEX_BOLT](state, action) {
      const { qty } = action.payload;
      let { parts } = action.payload;
      parts = parts.map((part) => {
        part.quantity = qty;
        part.suggested_quantity = qty;
        restorePartQty(state, part);
        return part;
      });
      const partsList = state.partsList
        .filter(part => part.number !== '310750')
        .filter(part => part.number !== '310751')
        .concat(parts);
      return { ...state, partsList };
    },
    [BomActionTypes.ADD_RM10_EVO_MODULE_CLAMP_KIT](state, action) {
      const { qty } = action.payload;
      let { parts } = action.payload;
      parts = parts.map((part) => {
        part.quantity = qty;
        part.suggested_quantity = qty;
        restorePartQty(state, part);
        return part;
      });
      const partsList = state.partsList
        .filter(part => part.number !== PART_RM10_EVO_MODULE_CLAMP_SIDE_BOLT_BULK)
        .filter(part => part.number !== PART_RM10_EVO_MODULE_CLAMP_BULK)
        .concat(parts);
      return { ...state, partsList };
    },
    [BomActionTypes.ADD_ATTACHMENT_TO_BOM](state, action) {
      const { attachment } = action.payload;
      const partsList = state.partsList.filter((part) => {
        return part.type !== attachment.type || part.number.includes('304001') || part.number.includes('304000') || part.number.includes('A1LF02M');
      });
      if (attachment.number.length) {
        const { extraParameters: { total_feet, productId } } = state; 
        if (isSMTiltOrFlush(productId)  && total_feet) {
          attachment['quantity'] = total_feet;
          attachment['suggested_quantity'] = total_feet;
        }
        restorePartQty(state, attachment);
        partsList.push(attachment);
      }
      return { ...state, partsList };
    },
    [BomActionTypes.ADD_CLAMPS](state: bomState, action) {
      const { midclamps, endclamps } = action.payload;
      const partsList = addClamps(state.partsList, midclamps, endclamps, state.productId);

      if (state.stdClampsFetching) {
        return { ...state, partsList, stdClampsFetching: false, stdClampsFetched: true };
      }

      return { ...state, partsList };
    },
    [BomActionTypes.ADD_NXT_CLAMPS](state: bomState, action) {
      const { clamps } = action.payload;
      const partsList = addNxtClamps(state.partsList,clamps);

      return { ...state, partsList };
    },
    [BomActionTypes.ADD_FRONT_TRIM_END_CAP](state:bomState, action) {

      const partsList = state.partsList;

      const isFrontTrim = partsList.find(part => part.number.includes('206072'));
      const isTrimClip = partsList.find(part => part.number.includes('008025'));


      if (!isFrontTrim || isTrimClip) {
        return { ...state };
      }

      const { clips, midclamps, endclamps } = action.payload;

      if (!clips) {
        return { ...state };
      }

      if(clips.quantity !== 0){

        clips.quantity = clips.quantity;
        restorePartQty(state, clips);
        clips.suggested_quantity = clips.quantity;

        partsList.push(clips);

      }


      partsList.map((part) => {
        if(part.type === midclamps.type){
          part.quantity += parseInt(midclamps.quantity)
          part.suggested_quantity += parseInt(midclamps.quantity)
        }
        if(part.type === endclamps.type){
          part.quantity += parseInt(endclamps.quantity)
          part.suggested_quantity += parseInt(endclamps.quantity)
        }
        return part;
      })

      return { ...state, partsList };


    },
    [BomActionTypes.REMOVE_EXTRA_MID_AND_END_CLAMP_QUANTITY](state:bomState, action) {
      const { midclamps, endclamps } = action.payload;
      const partsList = state.partsList
      partsList.map(part => {
        if(part.type === midclamps.type){
          part.quantity -= parseInt(midclamps.quantity);
          part.suggested_quantity -= parseInt(midclamps.quantity);
        }
        if(part.type === endclamps.type){
          part.quantity -= parseInt(endclamps.quantity);
          part.suggested_quantity -= parseInt(endclamps.quantity);
        }
      return part;
      })

      return { ...state, partsList };
    },
    [BomActionTypes.ADD_RAILS_TO_BOM](state, action) {
      let partsListWithoutRails = state.partsList
        .filter((part: bomPart) => part.type !== 'Rail')
        .filter((part: bomPart) => part.type !== 'Splice')
        .filter((part: bomPart) => part.type !== 'Cap Strip')
        .filter((part: bomPart) => part.type !== 'Cap Strip Screw');

      const attachmentKeywords = ['attachment', 'flashing'];
      const isPartAnAttachment = (part: bomPart) => attachmentKeywords.some(keyword => part.type.toLowerCase().includes(keyword));
      const isAttachmentPresent = action.payload.rails.rails.some((rail: bomPart) => isPartAnAttachment(rail));
      if (isAttachmentPresent) partsListWithoutRails = partsListWithoutRails.filter((part: bomPart) => !isPartAnAttachment(part));
      if (isSMTiltOrFlush(state.extraParameters.productId) || isNxtHorizon(state.extraParameters.productId) || isSMTiltPR(state.extraParameters.productId)) {
        partsListWithoutRails = partsListWithoutRails.map((part: bomPart)=>{
          if (isPartAnAttachment(part) && action.payload.rails.total_feet && !isSMTiltPR(state.extraParameters.productId) && !isNxtHorizon(state.extraParameters.productId)) { 
            part['quantity'] = action.payload.rails.total_feet;
            part['suggested_quantity'] = action.payload.rails.total_feet;
          }
          if (action.payload.rails?.total_splices && part.type.toLowerCase().includes("splice")) {
            part['quantity'] = action.payload.rails.total_splices;
            part['suggested_quantity'] = action.payload.rails.total_splices;
          }
          return part
        })
      }
      const rails = action.payload.rails.rails.map(rail => {
        let newRail = JSON.parse(JSON.stringify(rail))
        newRail.quantity = newRail.suggested_quantity
        return newRail;
      });
      const partsWithNewRails = partsListWithoutRails.concat(rails);
      let newState = { ...state, partsList: partsWithNewRails };
      const total_feet = action.payload.rails.total_feet; 
      const total_feet_5_deg_tilt =  action.payload.rails?.total_feet_5_deg_tilt ;
      const total_feet_10_deg_tilt = action.payload.rails?.total_feet_10_deg_tilt ; 
      if (total_feet) {
        const extraParameters = {...state.extraParameters, total_feet}
        newState = {...newState, extraParameters}
      }
      if (total_feet_5_deg_tilt > 0) {
        const extraParameters = {...state.extraParameters, total_feet_5_deg_tilt}
        newState = {...newState, extraParameters}
      }
      if (total_feet_10_deg_tilt > 0) {
        const extraParameters = {...state.extraParameters, total_feet_10_deg_tilt}
        newState = {...newState, extraParameters}
      }
      return newState;
    },
    [BomActionTypes.ADD_TILE_HOOK](state: bomState) {
      let attachment = state.attachmentsList['Tile Hook'].parts[0];
      if (attachment.number.length) {
        const { extraParameters: { total_feet, productId } } = state; 
        if (isSMTiltOrFlush(productId)  && total_feet) {
          attachment['quantity'] = total_feet;
          attachment['suggested_quantity'] = total_feet;
        }
        restorePartQty(state, attachment);
        state.partsList.push(attachment);
      }
      return { ...state };
    },
    [BomActionTypes.ADD_FLASHKIT_PRO](state: bomState) {
      let attachment = state.attachmentsList['Flashkit Pro'].parts[0];
      if (attachment.number.length) {
        const { extraParameters: { total_feet, productId } } = state; 
        if (isSMTiltOrFlush(productId)  && total_feet) {
          attachment['quantity'] = total_feet;
          attachment['suggested_quantity'] = total_feet;
        }
        restorePartQty(state, attachment);
        state.partsList.push(attachment);
      }
      return { ...state };
    },
    [BomActionTypes.ADD_FLASHKIT_PRO_SB](state: bomState) {
      restorePartQty(state, state.attachmentsList['Flashkit Pro SB'].parts[0]);
      state.partsList.push(state.attachmentsList['Flashkit Pro SB'].parts[0]);
      return { ...state };
    },
    [BomActionTypes.ADD_FLASHLOC_COMP_KIT](state: bomState) {
      let attachment = state.attachmentsList['FLASHLOC COMP KIT'].parts[0];
      if (attachment.number.length) {
        const { extraParameters: { total_feet, productId } } = state; 
        if (isSMTiltOrFlush(productId)  && total_feet) {
          attachment['quantity'] = total_feet;
          attachment['suggested_quantity'] = total_feet;
        }
        restorePartQty(state, attachment);
        state.partsList.push(attachment);
      }
      return { ...state };
    },
    [BomActionTypes.ADD_STRONGHOLD_ATT_COMP_NON_SEALANT](state: bomState) {
      state.partsList.filter(part => !part.number.includes('SHCPKT'));
      restorePartQty(state, state.attachmentsList['STRONGHOLD ATT COMP - NON SEALANT'].parts[0]);
      state.partsList.push(state.attachmentsList['STRONGHOLD ATT COMP - NON SEALANT'].parts[0]);
      return { ...state };
    },
    [BomActionTypes.ADD_STRONGHOLD_ATT_KIT_COMP](state: bomState) {
      state.partsList.filter(part => !part.number.includes('SHCPKT'));
      restorePartQty(state, state.attachmentsList['STRONGHOLD ATT KIT COMP'].parts[0]);
      state.partsList.push(state.attachmentsList['STRONGHOLD ATT KIT COMP'].parts[0]);
      return { ...state };
    },
    [BomActionTypes.ADD_TILE_REPLACEMENT_ITEM](state: bomState, action) {
      const { group, selectedGroupItem } = action.payload;
      let partToAdd = state.tileReplacementList[group][selectedGroupItem];
      let partNoPattern = partToAdd.number;
      if (group === 'tiles') { 
        partNoPattern = partNoPattern.slice(0, -1); // get the common part no pattern for all the color variants of a particular Tile
      }
      const shouldAddPart = !state.partsList.find(part => part.number.includes(partNoPattern) && part.type === partToAdd.type);
      if (shouldAddPart) {
        const { extraParameters: { total_feet, productId, } } = state; 
        if (isSMTiltOrFlush(productId)  && total_feet) {
          partToAdd['quantity'] = state.extraParameters.total_feet;
          partToAdd['suggested_quantity'] = state.extraParameters.total_feet;
        }
        state.partsList.push(state.tileReplacementList[group][selectedGroupItem]);
      }
      return { ...state };
    },
    [BomActionTypes.ADD_ATTACHMENT_ITEM](state: bomState, action) {
      const { group, selectedGroupItem } = action.payload;
      const partToAdd = state.attachmentsList[group][selectedGroupItem];
      const partNoPattern = partToAdd.number;
      const shouldAddPart = !state.partsList.find(part => part.number.includes(partNoPattern) && part.type === partToAdd.type);
      if (shouldAddPart) {
        state.partsList.push(state.attachmentsList[group][selectedGroupItem]);
      }
      return { ...state };
    },
    [BomActionTypes.REMOVE_TILE_REPLACEMENT_ITEM](state: bomState, action) {
      const { group, selectedGroupItem } = action.payload;
      const partToRemove = state.tileReplacementList[group][selectedGroupItem];
      let partNoPattern = partToRemove.number;
      if (group === 'tiles') {
        partNoPattern = partNoPattern.slice(0, -1); // get the common part no pattern for all the color variants of a particular Tile
      }
      const partsList = state.partsList.filter(part => !(part.number.includes(partNoPattern) && part.type === partToRemove.type));
      return { ...state, partsList };
    },
    [BomActionTypes.REMOVE_ATTACHMENT_ITEM](state: bomState, action) {
      const { group, selectedGroupItem } = action.payload;
      const partToRemove = state.attachmentsList[group][selectedGroupItem];
      const partNoPattern = partToRemove.number;
      const partsList = state.partsList.filter(part => !(part.number.includes(partNoPattern) && part.type === partToRemove.type));
      return { ...state, partsList };
    },
    [BomActionTypes.ADD_LFOOT](state: bomState) {
      const { partsList, attachmentsList } = state;

      const lFoot = partsList.find((part: bomPart) => part.number.includes('304001')  || part.number.includes('A1LF02M'));

      if (!lFoot) {
        const partToAdd = attachmentsList['2 Piece Aluminum Standoff'].parts[0];
        restorePartQty(state, partToAdd);
        const { extraParameters: { total_feet, productId, } } = state; 
        if (isSMTiltOrFlush(productId)  && total_feet) {
          partToAdd['quantity'] = state.extraParameters.total_feet;
          partToAdd['suggested_quantity'] = state.extraParameters.total_feet;
        }
        partsList.push(partToAdd);
      }

      return { ...state };
    },
    [BomActionTypes.FETCH_BOM_PART_LIST_REQUEST](state) {
      return { ...state, isLoading: true, isReady: false, error: null, partsList: [] };
    },
    [BomActionTypes.FETCH_BOM_PART_LIST_ERROR](state, action) {
      return { ...state, isLoading: false, error: action.payload.ajaxError };
    },
    [BomActionTypes.FETCH_BOM_PART_LIST_SUCCESS](state, action) {
      const { partsList, accessoriesList, attachmentsList, tileReplacementList, extraParameters, total_watts, total_modules, project_name, reload_selections, created, warningsInfo, } = action.payload;
      let restoredPartsList = partsList.map(part => {
        restorePartQty(action.payload, part);
        return part;
      });     
      return { ...state, isReady: true, partsList: restoredPartsList, accessoriesList, attachmentsList, tileReplacementList, extraParameters, total_watts, total_modules, project_name, reload_selections, created, warningsInfo,  };
    },
    [BomActionTypes.SET_BOM_PRODUCT](state, action) {
      const { productId, projectId } = action.payload;
      return { ...state, productId, projectId };
    },
    [BomActionTypes.BOM_RESET]() {
      return {
        total_watts: 0,
        total_modules: 0,
        partsList: [],
        accessoriesList: [],
        attachmentsList: [],
        selectedAttachmentType: [],
        extraParameters: {},
        isLoading: false,
        isReady: false,
        error: null,
        productId: 0,
        projectId: '',
        goToEngineeringFromBom: false,
        stdClampsFetching: false,
        stdClampsFetched: false,
        bomChanged: false,
        created: false,
        isClampsChanged: false,
      };
    },
    [BomActionTypes.SET_NEW_PART_QUANTITY](state, action) {
      const partsList = state.partsList;
      const partNumber = action.payload.partNumber;
      const newQty = action.payload.newQty;
      const modifedPartsList = partsList.reduce((list, part) => {
        if (part.number === partNumber) {
          part.quantity = newQty;
        }

        list.push(part);
        return list;
      },                                        []);
      return { ...state, partsList: modifedPartsList };
    },

    [BomActionTypes.REMOVE_PART_FROM_BOM](state, action) {
      const { partNumber } = action.payload;
      const index = state.partsList.findIndex(part => part.number === partNumber);
      const partsList = state.partsList;
      if (index > -1) {
        partsList.splice(index, 1);
      }
      return { ...state, partsList };
    },
    [BomActionTypes.SELECT_TILE_REPLACEMENT_TYPE](state, action) {
      const { group, selectedGroupItem } = action.payload;
      Object.keys(state.tileReplacementList[group]).map(key => {
        state.tileReplacementList[group][key].selected = key === selectedGroupItem;
      });
      return state;
    },
    [BomActionTypes.SELECT_ROOF_ATTACHMENT_TYPE](state, action) {
      const { group, selectedGroupItem } = action.payload;
      const standingSeamMetalGroup = group === "standing seam not verified" || group === "standing seam metal attachments";
      if(state.attachmentsList[group] && (state.attachmentsList[group].hasOwnProperty(selectedGroupItem) || standingSeamMetalGroup)){
        Object.keys(state.attachmentsList[group]).map(key => {
          state.attachmentsList[group][key].selected = key === selectedGroupItem;
        });
      }
      return state;
    },
    [BomActionTypes.SELECT_ATTACHMENT_TYPE](state, action) {
      const { attachmentType } = action.payload;
      Object.keys(state.attachmentsList).map((type) => {
        state.attachmentsList[type].selected = type === attachmentType;
      });
      let partsList = state.partsList.filter(part => (part.type !== 'Roof Attachment' || part.number.includes('304001') || part.number.includes('304000') || part.number.includes('A1LF02M')) && part.type !== 'Flashing' && part.type !== 'Lag Bolt' && part.type !== 'Washer');

      if (isSFMFamily(state.productId)) {
        const SFMAttachmentPartNumbers = getSFMAttachmentsPartsNumbers(state.attachmentsList);
        partsList = state.partsList.filter(part => !SFMAttachmentPartNumbers.includes(part.number));
      }

      const selectedAttachmentType = state.attachmentsList[attachmentType];

      if (state.productId !== 12) {
        partsList = addSelectedFlashingToPartsList(selectedAttachmentType, partsList, state.extraParameters);
        partsList = addSelectedStandoffsToPartsList(selectedAttachmentType, partsList, state.extraParameters);
      }

      if (isSFMFamily(state.productId)) {
        partsList = addSFMAttachmentsToPartsList(selectedAttachmentType.parts, partsList);
      }

      partsList = enableAllParts(partsList);

      return { ...state, selectedAttachmentType, partsList };
    },
    [BomActionTypes.SELECT_ATTACHMENT_TYPE_ON_LOAD](state, action) {
      const { attachmentType } = action.payload;
      const selectedAttachmentType = state.attachmentsList[attachmentType];
      return { ...state, selectedAttachmentType };
    },
    [BomActionTypes.SELECT_ATTACHMENT](state, action) {
      const { attachmentGroup, attachmentNumber } = action.payload;
      state.selectedAttachmentType[attachmentGroup] = state.selectedAttachmentType[attachmentGroup].map(attachment => selectAttachment(attachment, attachmentNumber));

      return { ...state };
    },

    [BomActionTypes.SELECT_LAG_BOLTS](state) {
      if (state.selectedAttachmentType.lag_bolts) {
        state.selectedAttachmentType.lag_bolts[0].selected = true;
      }
      return { ...state };
    },
    [BomActionTypes.UNSELECT_LAG_BOLTS](state) {
      if (state.selectedAttachmentType.lag_bolts && state.selectedAttachmentType.lag_bolts.length) {
        state.selectedAttachmentType.lag_bolts[0].selected = false;
      }
      return { ...state };
    },
    [BomActionTypes.DISABLE_LAG_BOLTS](state) {
      if (state.selectedAttachmentType.lag_bolts && state.selectedAttachmentType.lag_bolts.length) {
        state.selectedAttachmentType.lag_bolts[0].disabled = true;
      }
      return { ...state };
    },
    [BomActionTypes.ENABLE_LAG_BOLTS](state) {
      if (state.selectedAttachmentType.lag_bolts && state.selectedAttachmentType.lag_bolts.length) {
        state.selectedAttachmentType.lag_bolts[0].disabled = false;
      }
      return { ...state };
    },
    [BomActionTypes.DISABLE_PART](state, action) {
      const { partNumber } = action.payload;
      const partsList = state.partsList.map((part) => {
        if (part.number === partNumber) {
          part.disabled = true;
        }

        return part;
      });

      return { ...state, partsList };
    },
    [BomActionTypes.ENABLE_PART](state, action) {
      const { partNumber } = action.payload;
      const partsList = state.partsList.map((part) => {
        if (part.number === partNumber) {
          part.disabled = false;
        }

        return part;
      });

      return { ...state, partsList };
    },
    [BomActionTypes.FETCH_STANDARD_CLAMPS](state) {
      return { ...state, stdClampsFetching: true };
    },


    [BomActionTypes.REMOVE_FRONT_TRIM_END_CAP](state) {
      const partsList = state.partsList.filter(part => part.type !== 'Trim Clip');

      return { ...state, partsList };
    },
    [BomActionTypes.CHANGE_BOM_COLOR_VARIANT](state, action) {
      const { partNrToReplace, newPart } = action.payload;
      const newPartsList = state.partsList.map((part) => {
        if (part.number === partNrToReplace) {
          return newPart;
        }
        return part;
      });
      return { ...state, partsList: newPartsList };
    },
    [BomActionTypes.SET_BOM_PK](state, action) {
      const { bomPk } = action.payload;
      return { ...state, bomPk };
    },
    [BomActionTypes.CLEAR_BOM_PK](state, action) {
      return { ...state, bomPk: null };
    },
    [BomActionTypes.REMOVE_TILE_HOOK](state: bomState) {
      const partsList = state.partsList.filter(part => !part.number.includes('004008'));
      return { ...state, partsList };
    },
    [BomActionTypes.REMOVE_LFOOT](state: bomState) {
      const partsList = state.partsList.filter(part => !part.number.includes('304001') && !part.number.includes('304000') && !part.number.includes('A1LF02M'));
      return { ...state, partsList };
    },
    [BomActionTypes.REMOVE_FLASHKIT_PRO](state: bomState) {
      const partsList = state.partsList.filter(part => !part.number.includes('004050'));
      return { ...state, partsList };
    },
    [BomActionTypes.REMOVE_FLASHKIT_PRO_SB](state: bomState) {
      const partsList = state.partsList.filter(part => !part.number.includes('004015'));
      return { ...state, partsList };
    },
    [BomActionTypes.REMOVE_FLASHLOC_COMP_KIT](state: bomState) {
      const partsList = state.partsList.filter(part => !part.number.includes('004085'));
      return { ...state, partsList };
    },
    [BomActionTypes.REMOVE_STRONGHOLD_ATT_COMP_NON_SEALANT](state: bomState) {
      const partsList = state.partsList.filter(part => !part.number.includes('SHCPKT'));
      return { ...state, partsList };
    },
    [BomActionTypes.REMOVE_STRONGHOLD_ATT_KIT_COMP](state: bomState) {
      const partsList = state.partsList.filter(part => !part.number.includes('SHCPKT'));
      return { ...state, partsList };
    },
    [BomActionTypes.SELECT_ACCESSORY](state: bomState, action) {
      const { partNumber } = action.payload;
      const { accessoriesList } = state;

      const index = accessoriesList.findIndex(accessory => accessory.number === partNumber);

      state.accessoriesList[index].selected = true;

      return { ...state };
    },
    [BomActionTypes.UNSELECT_ACCESSORY](state: bomState, action) {
      const { partNumber } = action.payload;
      const { accessoriesList } = state;

      const index = accessoriesList.findIndex(accessory => accessory.number === partNumber);

      state.accessoriesList[index].selected = false;

      return { ...state };
    },
    [BomActionTypes.BOM_SELECTION_LOADING_START](state) {
      return { ...state, bomSelectionsLoading: true };
    },
    [BomActionTypes.BOM_SELECTION_LOADING_STOP](state: bomState) {
      return { ...state, bomSelectionsLoading: false };
    },
    [BomActionTypes.GO_TO_ENGINEERING_FROM_BOM](state) {
      return { ...state, goToEngineeringFromBom: true };
    },
    [BomActionTypes.DONT_GO_TO_ENGINEERING_FROM_BOM](state) {
      return { ...state, goToEngineeringFromBom: false };
    },
    [BomActionTypes.REMOVE_PART_FROM_REMEMBERED_QTYS](state: bomState, action: { payload: { ids: string[] } }) {
      const { ids } = action.payload;
      ids.map(id => delete state.extraParameters.selections[id]);
      return { ...state };
    },
    [BomActionTypes.BOM_CHANGED](state: bomState) {
      return { ...state, bomChanged: true, created: false };
    },
    [BomActionTypes.CLAMPS_CHANGED](state: bomState, action) {
      const clampsChanged = action.payload.clampsChanged ? true : false;
      return { ...state, isClampsChanged: clampsChanged };
    },
    [BomActionTypes.UPDATE_QUANTITY](state: bomState, action) {
      const {accessory} = action.payload;
      const productId = state.productId;
      let extraParameters = state.extraParameters;
      if(accessory.number === NEW_NXT_MLPE_LUG_CLAMP_FOR_LUG_V1 || accessory.number === 'LUGMLPE1M'|| accessory.number === 'LUGMLPE1R') {
        const bom_part = state.partsList.find(part => part.number === NEW_NXT_MLPE_LUG_CLAMP_FOR_LUG_V1);
        const nxt_horizon_for_lug_selected =  state.accessoriesList.find(acc => acc.number === NEW_NXT_MLPE_LUG_CLAMP_FOR_LUG_V1).selected;
        const nxt_horizon_for_mlpe_selected = state.accessoriesList.find(acc => acc.number === 'LUGMLPE1M').selected;
        const nxt_horizon_for_row_to_row_bonding_selected = extraParameters.row_to_row_bonding_qty > 0 ? state.accessoriesList.find(acc => acc.number === 'LUGMLPE1R').selected : false;
        if(bom_part) {
          if(nxt_horizon_for_mlpe_selected && nxt_horizon_for_lug_selected && nxt_horizon_for_row_to_row_bonding_selected) {
            [bom_part.quantity,bom_part.suggested_quantity] = [extraParameters.total_array_qty + state.total_modules + (extraParameters.row_to_row_bonding_qty) , extraParameters.total_array_qty + state.total_modules + (extraParameters.row_to_row_bonding_qty)];
          }
          else if (nxt_horizon_for_row_to_row_bonding_selected && nxt_horizon_for_mlpe_selected) {
            [bom_part.quantity,bom_part.suggested_quantity] = [(extraParameters.row_to_row_bonding_qty) + state.total_modules, (extraParameters.row_to_row_bonding_qty) + state.total_modules]
          }
          else if (nxt_horizon_for_row_to_row_bonding_selected && nxt_horizon_for_lug_selected) {
            [bom_part.quantity,bom_part.suggested_quantity] = [extraParameters.total_array_qty + (extraParameters.row_to_row_bonding_qty) , extraParameters.total_array_qty + (extraParameters.row_to_row_bonding_qty)];
          }
          else if(nxt_horizon_for_mlpe_selected && nxt_horizon_for_lug_selected) {
            [bom_part.quantity,bom_part.suggested_quantity] = isNxtHorizon(productId) ? [state.total_modules , state.total_modules] : [extraParameters.total_array_qty + state.total_modules , extraParameters.total_array_qty + state.total_modules];
          }
          else if (nxt_horizon_for_mlpe_selected) {
            [bom_part.quantity,bom_part.suggested_quantity] = [state.total_modules, state.total_modules];
          }
          else if (nxt_horizon_for_lug_selected) {
            [bom_part.quantity,bom_part.suggested_quantity] = [extraParameters.total_array_qty, extraParameters.total_array_qty];
          }
          else if (nxt_horizon_for_row_to_row_bonding_selected) {
            [bom_part.quantity,bom_part.suggested_quantity] = [extraParameters.row_to_row_bonding_qty, extraParameters.row_to_row_bonding_qty];
          }
        }
      }
      else {
        const bom_part = state.partsList.find(part => part.number === accessory.number);
        const rm_roof_pad = state.extraParameters.rm_roof_pad
        if (!rm_roof_pad.is_selected ){
          [rm_roof_pad.qty,bom_part.quantity,bom_part.suggested_quantity] = [bom_part.quantity,accessory.quantity,accessory.suggested_quantity]
        }
        else{
          [accessory.quantity,bom_part.quantity,bom_part.suggested_quantity] = [bom_part.quantity,rm_roof_pad.qty,rm_roof_pad.suggestedQty]}
        rm_roof_pad.is_selected = !rm_roof_pad.is_selected
      }
      return { ...state};
    },
    [BomActionTypes.ADD_RM10_EVO_MODULE_CLAMP_SIDE_BOLT_BULK](state, action) {
      const { qty } = action.payload;
      let { parts } = action.payload;
      parts = parts.map((part) => {
        part.quantity = qty;
        part.suggested_quantity = qty;
        restorePartQty(state, part);
        return part;
      });
      const partsList = state.partsList
        .filter(part => part.number !== PART_RM10_EVO_MODULE_CLAMP_KIT)
        .concat(parts);
      return { ...state, partsList };
    },
    [BomActionTypes.ADD_RM10_EVO_MODULE_CLAMP_BULK](state, action) {
      const { qty } = action.payload;
      let { parts } = action.payload;
      parts = parts.map((part) => {
        part.quantity = qty;
        part.suggested_quantity = qty;
        restorePartQty(state, part);
        return part;
      });
      const partsList = state.partsList
        .filter(part => part.number !== PART_RM10_EVO_MODULE_CLAMP_KIT)
        .concat(parts);
      return { ...state, partsList };
    },
    [BomActionTypes.ADD_T_BOLTS_009020S_FOR_SOLARHOOKS](state, action) {
      const { qty } = action.payload;
      let { parts } = action.payload;
      parts = parts.map((part) => {
        part.quantity = qty;
        part.suggested_quantity = qty;
        restorePartQty(state, part);
        return part;
      });
      const partsList = state.partsList
        .filter(part => part.number !== BND_T_BOLT_NUT_3_8_3_4_SS)
        .concat(parts);
      return { ...state, partsList };
    },
    [BomActionTypes.SET_AURORA_REPORTS_DATA_URLS](state: bomState, action) {
      const auroraReportsDataUrl = action.payload.auroraReportsDataUrl
      
      state.auroraReport.auroraReportsDataUrl = auroraReportsDataUrl;
      state.auroraReport.show = true;
      
      return { ...state};
    },
    [BomActionTypes.SEND_BOM_DATA_TO_AURORA_SUCCESS](state: bomState) {
      state.auroraReport.loading = false;
      return { ...state };
    },
    [BomActionTypes.SAVE_BOM_FOR_AURORA](state: bomState) {
      return { ...state, auroraReport: {show: true, loading: true}};
    },
    [BomActionTypes.HIDE_MODAL_FOR_AURORA_REPORTS](state: bomState) {
      state.auroraReport.show = false;
      state.auroraReport.loading = false;
      return { ...state};
    },
    [BomActionTypes.ENABLE_RETRY_BUTTON_FOR_SEND_BOM_DATA](state: bomState) {
      state.auroraReport.diableRetryButton = false;
      return { ...state};
    },
    [BomActionTypes.DISABLE_RETRY_BUTTON_FOR_SEND_BOM_DATA](state: bomState) {
      state.auroraReport.diableRetryButton = true;
      return { ...state };
    },
    [BomActionTypes.SET_AURORA_LOADER](state: bomState) {
      state.auroraReport.loading = true;
      return { ...state };
    },
    [BomActionTypes.CLEAR_AURORA_LOADER](state: bomState) {
      state.auroraReport.loading = false;
      return { ...state };
    },
    [BomActionTypes.SET_AURORA_ERROR](state: bomState, action) {
      state.auroraReport.errorMessages = action.payload.errorMessages;
      state.auroraReport.error = true;
      return { ...state };
    },
    [BomActionTypes.CLEAR_AURORA_ERROR](state: bomState) {
      state.auroraReport.errorMessages = null;
      state.auroraReport.error = false;
      return { ...state };
    },
  }),
};

